.outerDiv {
    padding: 14px 7px;
    background-color: rgba(255, 255, 255, 0.80);
    border-radius: 10px;
}

.select {
    border-radius: 10px;
    border-color: transparent;
}

.title {
    text-transform: none;
}

.selectWrapper {
    margin: 5px 0px;
    padding: 0px 0.4rem;
}

.searchBtn {
    background-color: var(--re-blue);
    border: 0px solid transparent;
    border-radius: 10px;
    color: var(--re-white);
    padding: 5px 10px;
    width: 100%;
    margin-top: 5px;
    font-size: var(--re-text-2);
}

.searchBtnWrapper {
    padding: 0px 0.4rem;
}

.advancedSearch {
    color: var(--re-black);
    text-align: center;
    display: block;
    margin-top: 20px;
    cursor: pointer;
    text-decoration: none;
    /* font-size: var(--re-text-2) */
}

.codeValStr {
    color: rgb(174, 0, 0);
    font-size: var(--re-text-small);
    margin-left: 10px;
    padding-top: 5px;
    display: block;
}

/* md */
@media screen and (min-width: 768px) {
    .outerDiv {
        padding: 28px 14px;
    }
}

@media screen and (min-width: 992px) {
    .title {
        margin-bottom: 20px;
    }
    .outerDiv {
        padding: 13px 15px;
    }

    .outerDiv {
        padding: 35px 20px;
    }
}