.menuDiv {
    background-color: var(--re-white);
    min-width: 100%;
    width: 100vw;
    border-bottom: 1px solid var(--re-gray);
    transition: height 1s ease, border-color 0.5s ease;
    height: 0px;
    overflow: hidden;
}

.menuDiv div ul {
    padding-left: 0px;
    padding-top: 15px;
}

.menuDiv div ul li {
    list-style-type: none;
    font-size: var(--re-text-2);
    color: var(--re-blue);
}

.menuDiv div ul li a {
    text-decoration: none;
    color: var(--re-blue);
    padding: 15px 20px;
    display: block;
}

.open {
    height: 250px;
}

.ul {
    white-space: nowrap;
}

@media screen and (min-width: 768px) {
    .open {
        height: 280px;
    }
}

@media screen and (min-width: 992px) {

    .menuDiv {
        height: auto;
        border: none;
        min-width: auto;
        -webkit-transition: 0s;
        -moz-transition: 0s;
        -o-transition: 0s;
        transition: 0s;
        display: flex;
        justify-content: end;
    }

    .menuDiv div ul {
        display: flex;
        padding-top: 0px;
        margin-bottom: 0px;
        height: 100%;
    }

    .menuDiv div ul li {
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    .homeLi {
        display: none !important;
    }

    .menuDiv div ul li a {
        display: inherit;
    }
}

@media screen and (min-width: 1200) {
    .homeLi {
        display: flex !important;
    }
}