.wrapper {
    border-radius: 10px;
    border: none;
    width: 100%;
    background-color: white;
    display: flex;
    justify-content: space-between;
    cursor: text;
    height: 38px;
    padding: 0px 10px;
    position: relative;
}

.placeholderDiv {
    color: hsl(0, 0%, 50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.clear {
    display: none;
    cursor: pointer;
    color: hsl(0, 0%, 50%);
}

.clear.show {
    display: block;
}

.inputWrapper {
    position: absolute;
    top: 0px; 
    left: 0px;
    width: 100%;
    height: 100%;
    /* border: 1px solid black; */
    display: flex;
    padding: 0px 10px;
}

.inputWrapper > div {
    /* border: 1px solid black; */
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.inputWrapper .prefix {
    visibility: hidden;
}

.placeholder {
    height: 100%;
}

.active .placeholderDiv {
    visibility: hidden;
}

.active .inputWrapper .prefix{
   visibility: visible; 
}

.txtInput {
    display: block;
    background-color: transparent;
    border: none;
    outline: none;
    width: 100%;
}

.clear {
    padding: 0px 10px;
}

.prefix {
    font-size: 10px;
    padding-right: 5px;
}

@media screen and (min-width: 1200px) {
    /* .inputWrapper {
        flex-direction: column;
        padding: 2px 15px;
    }

    .prefix {
        font-size: 10px;
    }

    .txtInput {
        position: relative;
        top: -3px;
        font-size: 12px;
        padding-left: 5px;
    } */

    .txtInput {
        font-size: 14px
    }

}