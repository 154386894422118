.wrapper {
    display: flex;
}

.wrapper > :first-child {
    /* border-left: 1px solid var(--re-gray); */
}

.wrapper > div {
    padding: 10px 0px;
    width: 35px;
    text-align: center;
    /* border-top: 1px solid var(--re-gray); */
    /* border-bottom: 1px solid var(--re-gray); */
    /* border-right: 1px solid var(--re-gray); */
    color: var(--re-gray);
    font-size: var(--re-text-1);
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
}

.wrapper > .selected, .wrapper > div:hover {
    background-color: var(--re-blue);
    color: var(--re-white);
}

.dots {
    padding: 10px 13px !important;
    background-color: transparent !important;
    color: black !important;
}