.topMainDiv {
    background-color: var(--re-blue);
    height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: var(--re-white);
}

.topMidDiv {
    display: flex;
    justify-content: center;
    font-size: 16px;
}

.socialLink {
    text-decoration: none;
    color: var(--re-white);
    font-size: 30px;
}

@media screen and (min-width: 992px) {
    .topMidDiv {
        justify-content: space-between;
    }
}