.wrapper {
    padding: 20px 0px;
    font-size: var(--re-text-2);
}

.errorImg {
    width: 300px;
}

.errorText {
    font-size: var(--re-text-4);
    margin-top: 10px;
    color: var(--re-blue);
    margin-bottom: 10px;
    text-align: center;
}

.button {
    display: block;
    padding: 5px 10px;
    background-color: var(--re-blue);
    margin: 10px 5px;
    color: white;
    font-size: var(--re-text-1);
    text-decoration: none;
    cursor: pointer;
    width: 15rem;
    text-align: center;
    border-radius: 5px;
}

@media screen and (min-width: 768px) {
    
}