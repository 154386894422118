#menu1Button span:nth-child(1) {
  top: 0px;
}

#menu1Button span:nth-child(2),#menu1Button span:nth-child(3) {
  top: 15px;
}

#menu1Button span:nth-child(4) {
  top: 30px;
}

#menu1Button.open span:nth-child(1) {
  top: 0px;
  width: 0%;
  left: 50%;
}

#menu1Button.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

#menu1Button.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

#menu1Button.open span:nth-child(4) {
  top: 10px;
  width: 0%;
  left: 50%;
}

#menu1Button {
  width: 40px;
  height: 40px;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
}

#menu1Button span {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  background: var(--re-gray);
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}