.errorDiv {
    text-align: center;
    margin-top: 40px;
    margin-bottom: 60px;
}

.errorDiv > div {
    font-size: 3.5rem;
}

.errorDiv > p > a {
    color: black;
}

.bottomDiv {
    position: fixed;
    bottom: 0px;
    background-color: white;
    /* border-top-width: 1px; */
    /* border-top-color: var(--re-gray); */
    border-top: 1px solid var(--re-gray);
    width: 100vw;
    padding-top: 12px;
    padding-bottom: 12px;
    z-index: 2;
}

.interestedBtn {
    background-color: var(--re-blue);
    border: none;
    color: white;
    padding: 10px;
    font-size: var(--re-text-3);
    width: calc(100% - 70px);
}

.contactMdlWrapper {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0px;
    left: 0px;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 3;
    overflow: scroll;
}

.priceDiv {
    margin-bottom: 40px;
}

.closeBtn {
    padding: 10px 20px;
    background-color: white;
}

.closeBtnDiv {
    width: 100%;
    display: flex;
    justify-content: end;
    margin-top: 10px;
}

.contactWrapper {
    background-color: var(--contact-form-gray);
}

.contactDiv {
    max-width: 450px;
    margin: auto;
}

@media screen and (min-width: 576px) {
    .interestedBtn {
        width: 100%;
        max-width: calc(100vw - 150px);
    }
}

@media screen and (min-width: 768px) {
    .bottomDiv {
        display: none;
    }
}

/* lg */
@media screen and (min-width: 992px) {
    .stickyDiv {
        position: sticky;
        top: -70px;
        /* z-index: 5000;  */
    }

    .sidevarDiv {
        position: relative;
        /* width: 100%; */
        min-height: 1px;
        margin-top: 30px;
        margin-bottom: 60px;
    }
}