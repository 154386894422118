.propertySearchWrapper {
    height: 500px;
    background-image: url("../../assets/foto_manizales.jpg");
    background-repeat: no-repeat;
    background-position: 7% 0%;
    background-size: cover;
}

.aboutTitleWrapper {
    position: absolute;
    transform: translateY(-54px);
    display: flex;
    width: 100%;
    justify-content: center;
}

.aboutTitleWrapper div {
    background-color: white;
    height: 55px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px 40px 0px 40px;
    border-radius: 20px 20px 0px 0px;
}

.officeImgWrapper {
    /* border: 1px solid black; */
    height: 250px;
    width: 100%;
    margin-bottom: 25px;
}

.officeImgColor {
    position: absolute;
    width: 200px;
    height: 150px;
    background-color: var(--re-blue);
    z-index: -1;
}

/* .officeImgDiv {
    height: 90%;
    width: 90%;
    background-image: url("../../assets/Oficina1.jpg");
    transform: translate(10%, 10%);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 20% 50%;
} */

.testimonialsWrapper {
    height: 650px;
    background-image: url("../../assets/partners.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 75% 0%;
}

.testimonialsTitleWrapper {
    transform: translateY(-1px);
}

.testimonialsTitleWrapper div {
    border-radius: 0px 0px 20px 20px;
    padding-top: 0px;
    padding-bottom: 5px;
}

.testimonialsContentWrapper {
    padding-top: 110px;
    background-color: rgba(87, 79, 62, 0.4);
    height: 100%;
}

.contactWrapper {
    padding: 17px 0px;
}

.inmuTitle {
    text-align: center;
    margin: 50px 0px 15px 0px;
    font-weight: bold;
}

/* sm */
@media screen and (min-width: 576px) {
    .aboutTitleWrapper div {
        padding: 10px 100px 0px 100px;
    }

    .officeImgWrapper {
        height: 300px;
    }
    
    .testimonialsTitleWrapper div {
        padding-top: 0px;
        padding-bottom: 10px;    
    }

    .contactWrapper {
        padding: 30px 0px;
    }

    .inmuTitle {
        margin: 55px 0px 20px 0px;
    }
}

/* md */
@media screen and (min-width: 768px) {
    .aboutTitleWrapper {
        transform: translateY(-65px);
    }

    .aboutTitleWrapper div {
        height: 66px;
        padding: 10px 100px 0px 100px;
    }

    .officeImgWrapper {
        height: 350px;
    }

    .officeImgColor {
        width: 300px;
        height: 200px;
    }

    .testimonialsTitleWrapper {
        transform: translateY(-1px);
        padding-top: 0px;
        padding-bottom: 10px;
    }

    .inmuTitle {
        margin: 60px 0px 25px 0px;
    }
}

/* lg */
@media screen and (min-width: 992px) {
    .aboutTitleWrapper div {
        padding: 10px 120px 0px 120px;
    }

    .officeImgColor {
        width: 200px;
        height: 140px;
    }

    .officeImgWrapper {
        height: 100%;
    }

    .testimonialsTitleWrapper div {
        padding-top: 0px;
        padding-bottom: 7px;
    }

    .contactWrapper {
        padding: 50px 0px;
    }

    .inmuTitle.first {
        margin: 50px 0px 25px 0px;
    }

    .inmuTitle {
        margin: 100px 0px 25px 0px;
    }
}

/* xl */
@media screen and (min-width: 1200px) {
    .propertySearchWrapper {
        background-position-y: 20%;
    }

    .contactWrapper {
        padding: 70px 0px;
    }

    .inmuTitle.first {
        margin: 50px 0px 25px 0px;
    }

    .inmuTitle {
        margin: 100px 0px 30px 0px;
    }
}