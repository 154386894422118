.sentWrapper {
    max-width: 220px;
    margin: auto;
    padding: 43px 0px;
}

.infoDiv {
    text-align: center;
}

.infoDiv {
    margin-top: 25px;
}

.infoDiv p {
    margin: 0px;
}

.sep {
    margin-top: 25px;
    margin-bottom: 0px;
}

.thanks {
    font-size: var(--re-text-3);
}

.name {
    font-weight: bold;
    color: var(--re-blue);
}

.infoDiv .phoneTitle {
    margin-top: 15px;
    font-size: var(--re-text-small);
}

.phone {
    color: rgb(56, 167, 208);
    text-decoration: none;
    display: block;
}