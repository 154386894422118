.title {
    margin-top: 30px;
    margin-bottom: 10px;
}

.feature {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin: 8px 5px;
}