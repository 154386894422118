.mainDiv {
    margin: 10px 0px;
}

.label {
    font-size: var(--re-text-small);
}

.input {
    border-radius: 0px;
    border: 1px solid var(--re-second-gray);
}

.input:focus {
    box-shadow: none;
    border: 1px solid var(--re-second-gray);
    border-bottom: 1px solid var(--re-blue);
}

.formGroup {
    margin-top: 10px;
}

.submitBtn {
    margin-top: 10px;
    margin-bottom: 10px;
    border: 1px solid var(--re-gray);
    padding: 5px 10px;
}

.whatsAppDiv {
    padding-top: 10px;
}

.valLabel {
    font-size: var(--re-text-small);
    color: var(--error-red);
    margin-bottom: 0px;
    margin-left: 5px;
}

.phoneInput input, .phoneInput input:focus, .phoneInput input:focus-visible {
    border: none;
    outline: none;
}

.phoneInput {
    border-radius: 0px;
    border: 1px solid var(--re-second-gray);
}

.phoneInput:global(.PhoneInput--focus) {
    border: 1px solid var(--re-second-gray);
    border-bottom: 1px solid var(--re-blue);
}

.errorDiv {
    background-color: var(--error-red);
    padding: 5px 10px;
    margin-top: 10px;
    border-radius: 5px;
}

.errorDiv p {
    color: white;
    margin: 0px;
    /* font-size: var(--re-text-small); */
}

.sendInfoChkLab {
    font-size: 14px;
}

.submitBtn.disabled {
    cursor:not-allowed;
    border: 1px solid #999999;
    background-color: #cccccc;
    color: #666666;
}

.privacyNoticeDiv {
    margin-top: 20px;
    font-size: var(--re-text-small);
    padding: 0px 10px;
    color: var(--re-gray);
    /* border: 1px solid var(--re-second-gray); */
    /* border-radius: 10px; */
}

.privacyNoticeDiv p {
    /* margin-bottom: 0px; */
}