:root {
  --re-blue: #0E0E4A;
  --re-gray: #606060;
  --re-second-gray: #c4c4c4;
  --re-third-gray: #ececec;
  --re-white: white;
  --re-black: black;
  --re-md-div-space: 80px;
  --re-sm-div-space: 10px;
  --loading-grey: rgb(220, 220, 220);
  --error-red: rgb(150, 0, 0);
  --contact-form-gray: #ECECEC;
  font-size: 14px;
  --re-text-1: 1rem;
  --re-text-2: 1.25rem;
  --re-text-3: 1.5rem;
  --re-text-4: 2rem;
  --re-text-small: 0.75rem;
  --swiper-pagination-color: var(--re-blue);
  --swiper-navigation-color: var(--re-blue);
}

body {
  font-family: "Open Sans", sans-serif;
  scroll-behavior: smooth;
}

h1, .h1 {
  font-size: var(--re-text-4);
  /* color: var(--re-blue); */
  font-weight: bold;
  margin-bottom: 20px;
  margin-top: 20px;
}

h2, .h2 {
  font-size: var(--re-text-3);
  color: var(--re-blue);
  text-transform: uppercase;
  margin-top: 40px;
  margin-bottom: 20px;
}

h3, .h3 {
  font-size: var(--re-text-2);
  font-weight: bold;
}

h4, .h4 {
  font-size: var(--re-text-4);
  font-weight: bold;
}

.re-bg-blue {
  background-color: var(--re-blue);
}

.leaflet-pane {
  z-index: 0;
}

.leaflet-bottom, .leaflet-top {
  z-index: 1;
}

.re-color-blue {
  color: var(--re-blue);
}

.re-bg-gray {
  background-color: var(--re-gray);
}

.re-bg-second-gray {
  background-color: var(--re-second-gray);
}

.re-bg-third-gray {
  background-color: var(--re-third-gray);
}

.contact-div-bg {
  background-color: var(--contact-form-gray);
}

.re-color-gray {
  color: var(--re-gray);
}

.re-color-second-gray {
  color: var(--re-second-gray);
}

.re-color-third-gray {
  color: var(--re-third-gray);
}

.re-bg-black {
  background-color: var(--re-black);
}

.re-color-black {
  color: var(--re-black);
}

.re-bg-white {
  background-color: var(--re-white);
}

.re-color-white {
  color: var(--re-white);
}

.re-container {
  margin-left: var(--re-sm-div-space);
}

.text-size-small {
  font-size: var(--re-text-small);
}

.text-size-1 {
  font-size: var(--re-text-1);
}

.text-size-2 {
  font-size: var(--re-text-2);
}

.text-size-3 {
  font-size: var(--re-text-3);
}

.text-size-4 {
  font-size: var(--re-text-4);
}

.no-dec-link {
  text-decoration: none;
}

.no-dec-link:hover {
  text-decoration: none;
}

.hidden-seo-tag {
  position: absolute;
  clip: rect(1px 1px 1px 1px); /* IE7 */
  clip: rect(1px, 1px, 1px, 1px);
}

.material-icons {
  vertical-align: bottom;
}

@keyframes loading {
  to {
    background-position-x: -20%;
  }
}

.animated-background {
  background-color: var(--loading-grey);
  background: linear-gradient(
    100deg,
    rgba(255, 255, 255, 0) 40%,
    rgba(124, 124, 124, 0.5) 50%,
    rgba(255, 255, 255, 0) 60%
  ) var(--loading-grey);
  background-size: 200% 100%;
  background-position-x: 180%;
  animation: 2s loading ease-in-out infinite;
}

/** sm */
@media screen and (min-width: 576px) {
  
}

/** md */
@media screen and (min-width: 768px) {
  :root {
    font-size: 16px;
  }
  /* :root {
    --re-text-1: 16px;
    --re-text-2: 20px;
    --re-text-3: 24px;
    --re-text-4: 32px;
  } */
}

/** lg */
@media screen and (min-width: 992px) {
  
}

/** xl */
@media screen and (min-width: 1200px) {
  
}