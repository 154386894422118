.logoImg {
    height: 50px;
    margin: 15px 0px;
}

.openButton {
    min-height: 100%;
    font-size: 30px;
    background-color: transparent;
    border: none;
    display: flex;
}

.openButtonIcon {
    padding: 25px 0px 25px 20px;
}

@media screen and (min-width: 576px) {
    .hr div {
        margin-left: calc(((540px - 100vw) / 2));
        margin-right: calc(((540px - 100vw) / 2));
    }
}

@media screen and (min-width: 768px) {
    .hr div {
        margin-left: calc(((720px - 100vw) / 2));
        margin-right: calc(((720px - 100vw) / 2));
    }
}

@media screen and (min-width: 992px) {
    .logoImg {
        height: 70px;
    }

    .openButton {
        display: none;
    }    

    .divWrapper {
        border-bottom: 1px solid var(--re-gray);
    }
}