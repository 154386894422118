.wrapper {
    background-color: rgba(108, 117, 125, 0.6);
    border-radius: 10px;
    padding: 20px 7px;
}

.title {
    color: var(--re-white);
    font-size: var(--re-text-4);
    text-align: center;
    margin-bottom: 20px;
}

.formRow {
    height: 144px;
    transition: height 1s;
}

.formRow.open, .formRow.opening {
    height: 480px;
}

.formRow.opening {
    overflow: hidden;
}

.closing {
    overflow: hidden;
}

.formRow > div {
    margin-bottom: 10px;
    overflow: visible;
    height: 38px;
}

.dNone {
    display: none;
}

/* .chevron {
    transition: 1s;
} */

.chevron.open {
    transform: rotate(180deg);
}


.showMoreLessBtn {
    background-color: transparent;
    color: var(--re-white);
    border:  none;
}

.submitBtnDiv {
    position: absolute;
    left: 0px;
    width: 100%;
}

.submitBtn {
    background-color: var(--re-blue);
    color: white;
    border: none;
    border-radius: 10px;
    padding: 7px 30px;
    font-size: var(--re-text-2);
    margin-right: 7px;
}

@media screen and (min-width: 576px) {
    .formRow {
        height: 96px;
        margin: 0px;
    }

    .formRow > div {
        padding: 0px 5px;
    }

    .formRow.open, .formRow.opening {
        height: 285px;
    }
}

@media screen and (min-width: 768px) {
    .formRow {
        height: 45px;
    }

    .formRow.open, .formRow.opening {
        height: 190px;
    }
}

@media screen and (min-width: 992px) {
    .formRow.open, .formRow.opening {
        height: auto;
    }

    .formRow {
        height: auto;
        margin-bottom: 10px;
    }


    .dNone {
        display: initial;
    }

    .showMoreLessBtn {
        display: none;
    }

}

@media screen and (min-width: 1200px) {
    .formRow > div {
        width: 20%;
    }

    .formRow {
        margin-bottom: 15px;
    }
}