.orderByWrapper {
    display: flex;
    justify-content: end;
    padding-top: 14px;
    padding-bottom: 14px;
}

.orderByWrapper > div {
    min-width: 300px;
}

.paginationWrapper {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 30px;
}

.noResults {
    text-align: center;
    color: black;
    font-size: var(--re-text-3);
}

.noResults > a {
    font-size: var(--re-text-2);
    color: black;
    margin-bottom: 40px;
    display: block;
}

.exclamation {
    font-size: 40px;
}

@media screen and (min-width: 576px) {
    .orderByWrapper {
        padding-top: 20px;
        padding-bottom: 20px;
    }
}

/** md */
@media screen and (min-width: 768px) {

}

/** lg */
@media screen and (min-width: 992px) {

}

/** xl */
@media screen and (min-width: 1200px) {
    .orderByWrapper {
        padding-top: 24px;
        padding-bottom: 24px;
    }
}
