.swiperWrapper {
    width: 100%;
    height: 100%;
    position: relative;
    border: 1px solid rgb(200, 200, 200);
}

.swiperControlDiv {
    position: absolute;
    top: 0px;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.swiperControlDiv > div {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.swiperControlDiv > div > div{
    color: var(--re-blue);
    font-size: 30px;
    padding: 15px;
    z-index: 1;
    cursor: pointer;
}

.swiperContainer {
    width: 100%;
    height: 100%;
}

.swiperSlide {
    height: 100%;
    width: 100%;
}

.img {
    max-width: 100%;
    height: 100%;
    margin: auto;
    display: block;
}