.contactInfoDiv {
    width: 100%;
}

.contactInfoDiv :nth-child(1) {
    font-size: var(--re-text-2);
    margin-bottom: 0px;
}

.contactInfoDiv * {
    font-size: var(--re-text-1);
    display: block;
    margin-bottom: 0px;
    overflow-wrap: anywhere;
}

.contactInfoDiv :last-child {
    margin-bottom: 11px;
}

.contactInfoDiv a {
    color: black;
    text-decoration: none /*underline solid var(--re-gray); */
}

.mapDiv {
    min-height: 300px;
}

.mapDiv iframe {
    min-height: 100%;
    width: 100%;
}

.contactWrapper {
    background-color: var(--contact-form-gray);
}

.contactDiv {
    max-width: 450px;
    margin: auto;
}

@media screen and (min-width: 992px) {
    .mapDiv {
        min-width: 40%;
        padding: 20px;
    }

    .mapDiv iframe {
        min-height: auto;
        height: 100%;
    }
}

@media screen and (min-width: 1200px) {
    .mapDiv {
        min-width: 50%;
        padding: 20px 40px;
    }
}