.title {
    margin-top: 30px;
    margin-bottom: 10px;
}

.detail {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin: 10px 5px;
}

.detailName {
    font-size: var(--re-text-small);
    margin-bottom: 0px;
    color: var(--re-gray);
    font-weight: bold;
}

.detailInfo {
    margin-bottom: 0px;
}