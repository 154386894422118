.loading {
    height: 1em;
    display: inline-block;
    width: 20em;
}

.separator {
    margin: 0px 7px;
    color: var(--re-gray);

}

.bcLabel {
    text-decoration: none;
    color: var(--re-gray);
}

.bcLabel:hover, .bcLabel:active {
    text-decoration: underline;
}