.outerDiv {
    background-color: var(--re-third-gray);
    border-radius: 10px;
    padding: 0px 20px;
    font-size: 2rem
}

.outerDiv button {
    background-color: transparent;
    border-color: transparent;
    padding: 10px 15px;
}

.playPouseBtn {
    width: 60px;
}