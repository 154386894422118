.wrapper {
    border: 1px solid rgb(200, 200, 200);
    padding: 20px 20px 10px 20px;
}

.detailInfo {
    font-size: var(--re-text-3);
    text-align: center;
    white-space: nowrap;
}

.detailInfo > sup {
    font-size: 0.75rem;
}

.detailLabel {
    font-size: var(--re-text-1);
    color: var(--re-gray);
    text-align: center;
    margin-bottom: 10px;
}