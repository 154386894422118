.upperWrapper {
    /* background-color: var(--re-blue); */
    /* text-align: center; */
    padding: 23px 0px;
}

.upperWrapper p {
    margin-bottom: 0px;
}

.pseButton {
    background-color: var(--re-blue);
    display: block;
    max-width: 200px;
    padding: 0 15px;
}

.infoDiv > :first-child {
    color: var(--re-black);
    /* font-size: var(--re-text-2); */
}

.infoDiv * {
    color: var(--re-gray);
}

.infoDiv a {
    /* color: var(--re-black); */
    display: block;
    text-decoration: none;
}

.infoDiv a:hover {
    text-decoration: underline;
}

.linksDiv a {
    color: var(--re-gray);
    text-decoration: none;
}

.linksDiv a:hover {
    text-decoration: underline;
}

.certImg {
    max-width: 120px;
}

.lowerWrapper {
    /* background-color: var(--re-gray); */
    padding: 0px 0px 30px 0px;
    text-align: center;
    margin-bottom: 80px;
}

.legalInfo {
    padding-bottom: 25px;
}

.lowerWrapper p {
    margin-bottom: 0px;
}

.lowerWrapper a {
    color: var(--re-black);
    text-decoration: none;
}

.lowerWrapper a:hover {
    text-decoration: underline;
}

.whatsAppBubleWrapper {
    position: fixed;
    bottom: 7px;
    right: 7px;
    border-radius: 50px;
    background-color: #25d366;
    z-index: 2;
}

.whatsAppBubleWrapper img {
    width: 60px;
}

@media screen and (min-width: 768px) {
    .whatsAppBubleWrapper {
        bottom: 15px;
        right: 15px;
    }

    .whatsAppBubleWrapper img {
        width: 60px;
    }
}

@media screen and (min-width: 992px) {
    .legalInfo {
        text-align: left;
        padding-bottom: 0px;
    }
    
    .developerInfo {
        text-align: right;
    }
    
    .upperWrapper {
        padding: 30px 0px;
    }

    .whatsAppBubleWrapper {
        bottom: 20px;
        right: 20px;
    }

    .whatsAppBubleWrapper img {
        width: 60px;
    }
}