.mainDiv {
    border: 1px solid rgb(220, 220, 220);
    margin-bottom: 20px;
}

.imageDiv {
    width: 100%;
    height: 224px;
    border-bottom: 1px solid rgb(220, 220, 220);
}

.image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.body {
    padding: 14px 15px 32px 15px;
}

.title {
    font-size: 16px;
    color: var(--re-blue);
    text-decoration: none;
    height: 40px;
    margin-bottom: 13px;
    
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.priceTitle {
    color: var(--re-gray);
    font-size: 13px;
    margin-bottom: 0px;
}

.price {
    color: black;
    font-size: 16px;
    margin-bottom: 13px;
}

.currency {
    color: var(--re-gray);
    font-size: 13px;
}

.attr :first-child {
    color: var(--re-gray);
    font-size: 13px;
    margin-bottom: 0px;
    white-space: nowrap;
}

.attr :nth-child(2) {
    color: black;
    font-size: 16px;
    margin-bottom: 0px;
}

.loading .body :nth-child(1) {
    height: 44px;
    margin-bottom: 13px;
}

.loading .body :nth-child(2) {
    height: 44px;
    margin-bottom: 13px;
}

.loading .body :nth-child(3) {
    height: 43px;
}

.noImgDiv {
    height: 100%;
    /* background-color: var(--re-gray); */
    color: var(--re-gray);
    padding: 0px 20px;
    text-align: center;
    font-size: 14px;
}

.areaLab > sup {
    font-size: 9px !important;
}