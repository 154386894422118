h1, .h1 {
    font-size: var(--re-text-4);
    font-weight: bold;
    color: black;
}

h2, .h2 {
    font-size: var(--re-text-3);
    text-transform: none;
    font-weight: bold;
    color: black;
}

h3, .h3 {
    font-size: var(--re-text-2);
    font-weight: bold;
}

h4, .h4 {
    font-size: var(--re-text-4);
    font-weight: bold;
}

.content-wrapper {
    background-color: var(--re-third-gray);
    padding: 40px 0px;
}

.main-content-div {
    padding: 30px;
}

.sidebar-div {
    padding: 30px;
}