.advancedSearchWrapper {
    background-image: url("../../assets/sala.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position-x: 50%;
    background-position-y: 100%;
    padding: 40px 0px;
}

.testDiv {
    height: 70px;
}

.errorDiv {
    text-align: center;
    margin-top: 40px;
    margin-bottom: 60px;
}

.errorDiv > div {
    font-size: 3.5rem;
}

.errorDiv > p > a {
    color: black;
}

.breadcrumbDiv {
    font-size: var(--re-text-small);
    margin: 15px 0px;
}

.breadcrumb {
    display: none;
}

.orderByDiv {
    margin-bottom: 15px;
}

.contactWrapper {
    background-color: var(--contact-form-gray);
    margin-top: 20px;
}

.contactDiv {
    max-width: 450px;
    margin: auto;
}

/** sm */
@media screen and (min-width: 576px) {
    .breadcrumb {
        display: inline;
    }

    .contactWrapper {
        margin-top: 30px;
    }
}

/** md */
@media screen and (min-width: 768px) {
    .advancedSearchWrapper {
        padding: 60px 0px;
    }

    .orderByDiv {
        margin-top: 15px;
    }

    .breadcrumbDiv {
        position: relative;
    }

    .breadcrumbSpan {
        position: absolute;
        bottom: 10px;
    }

    .paginationWrapper {
        margin: 10px 0px;
    }

    .contactWrapper {
        margin-top: 40px;
    }
}

/** lg */
@media screen and (min-width: 992px) {
    .orderByDiv {
        margin: 30px 0px;
    }

    .breadcrumbDiv {
        margin: 30px 0px;
    }

    .breadcrumbSpan {
        bottom: 20px;
    }

    .paginationWrapper {
        margin: 20px 0px;
    }
}

/** xl */
@media screen and (min-width: 1200px) {
    .advancedSearchWrapper {
        padding: 70px 0px;
    }

    .paginationWrapper {
        margin: 30px 0px;
    }

    .contactDiv {
        max-width: 600px;
    }
}

@media screen and (min-width: 1400px) {
    .advancedSearchWrapper {
        padding: 100px 0px;
    }
}