.outerDiv {
    height: 400px;
    width: 300px;
    border-radius: 10px;
    background-color: var(--re-third-gray);
    opacity: 0.8;
    padding: 25px;
}

.text {
    font-size: var(--re-text-2);
    text-align: center;
    margin-bottom: 0px;
}

.author {
    font-size: var(--re-text-3);
    text-align: center;
    margin-bottom: 0.1rem;
}

.authorDesc {
    font-size: var(--re-text-2);
    text-align: center;
    margin-bottom: 0px;
}

@media screen and (min-width: 768px) {
    .outerDiv {
        width: 370px;
        height: 420px;
    }
}