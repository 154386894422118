.titleWrapper {
    background-image: url("../../assets/handshake.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position-y: 25%;
}

.titleWrapper > div {
    background-color: rgba(0, 0, 0, 0.6);
    padding: 40px 0px;
}

.title {
    color: var(--re-white);
    text-align: center;
}

.contactWrapper {
    margin: 17px 0px;
}

@media screen and (min-width: 576px) {
    .titleWrapper {
        background-position-y: 40%;
    }

    .titleWrapper > div {
        padding: 50px 0px;
    }
}

@media screen and (min-width: 768px) {
    .titleWrapper {
        background-position-y: 40%;
    }

    .titleWrapper > div {
        padding: 60px 0px;
    }

    .contactWrapper {
        margin: 25px 0px;
    }
}

@media screen and (min-width: 992px) {
    .titleWrapper {
        background-position-y: 43%;
    }

    .titleWrapper > div {
        padding: 60px 0px;
    }

    .contactWrapper {
        margin: 30px 0px;
    }
}

@media screen and (min-width: 1200px) {
    .titleWrapper {
        background-position-y: 43%;
    }

    .titleWrapper > div {
        padding: 80px 0px;
    }

    .contactWrapper {
        margin: 40px 0px;
    }
}