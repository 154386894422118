.officeSwipperWrapper {
    height: 300px;
}

.officeSwipperContainer {
    width: 100%;
    height: 100%;
}

.officeSwiperSlide {
    height: 100%;
    width: 100%;
}

.officeImg {
    max-width: 100%;
    height: 100%;
    margin: auto;
    display: block;
}

.testimonialWrapper {
    overflow: visible;
    padding-top: 20px;
    position: relative;
}

.testimonialDiv {
    /* padding: 40px 50px 5px 50px; */
    padding: 40px 10px 5px 10px;
    text-align: center;
    border: 1px solid var(--re-third-gray);
    border-top: 10px solid var(--re-blue);
    overflow: visible;
    border-radius: 10px;
}

.testimonialQuote {
    font-style: italic;
}

.testimonialQuote p {
    height: calc(10em * 1.5);    
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 10;
    line-clamp: 10;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
}


.testimonialAuthor p {
    font-weight: bold;
    margin-bottom: 0px;
}

.testimonialDesc {
    font-size: small;
    padding-bottom: 15px
}

.testimonialIconDiv {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    display: flex;
    justify-content: center;
    overflow: visible;
}

.testimonialIconDiv div {
    background-color: white;
    padding: 10px;
    border-radius: 40px;
    border: 1px solid var(--re-third-gray);
    color: var(--re-blue);
    /* transform: translate(0px, -50%); */
}

@media screen and (min-width: 576px) {
    .testimonialQuote p {
        height: calc(5em * 1.5);    
        -webkit-line-clamp: 5;
        line-clamp: 5;
    }
}


@media screen and (min-width: 768px) {
    .testimonialQuote p {
        height: calc(4em * 1.5);    
        -webkit-line-clamp: 4;
        line-clamp: 4;
        vertical-align: middle;
    }
}

@media screen and (min-width: 992px) {
    .testimonialQuote p {
        height: calc(5em * 1.5);    
        -webkit-line-clamp: 5;
        line-clamp: 5;
        vertical-align: middle;
    }
}

@media screen and (min-width: 1200px) {
    .testimonialQuote p {
        height: calc(4em * 1.5);    
        -webkit-line-clamp: 4;
        line-clamp: 4;
        vertical-align: middle;
    }
}

@media screen and (min-width: 1400px) {
    .testimonialWrapper {
        padding-left: 30px;
        padding-right: 30px;
    }

    .testimonialQuote p {
        height: calc(4em * 1.5);    
        -webkit-line-clamp: 4;
        line-clamp: 4;
        vertical-align: middle;
    }
}
/* :global(.swiper-pagination-bullet-active) {
    background-color: var(--re-blue);
} */

/* .titleWrapper {
    background-image: url("../../assets/real-state.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position-y: 40%;
}

.titleWrapper > div {
    background-color: rgba(0, 0, 0, 0.6);
    padding: 40px 0px;
}

.title {
    color: var(--re-white);
    text-align: center;
}

.contactWrapper {
    margin: 17px 0px;
}

.officeImgWrapper {
    border: 1px solid black;
    height: 250px;
    width: 100%;
    margin-bottom: 25px;
}

.officeImgColor {
    position: absolute;
    width: 200px;
    height: 150px;
    background-color: var(--re-blue);
    z-index: -1;
}

.officeImgDiv {
    height: 90%;
    width: 90%;
    background-image: url("../../assets/Oficina1.jpg");
    transform: translate(10%, 10%);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 20% 50%;
}

.testimonialsWrapper {
    height: 650px;
    /* background-image: url("../../assets/partners.jpg"); */
    /* background-repeat: no-repeat;
    background-size: cover;
    background-position: 75% 0%;
}

.testimonialsContentWrapper {
    padding-top: 30px;
    /* background-color: rgba(87, 79, 62, 0.4);
    height: 100%;
}

@media screen and (min-width: 576px) {
    .titleWrapper {
        background-position-y: 40%;
    }

    .titleWrapper > div {
        padding: 50px 0px;
    }

    .officeImgWrapper {
        height: 300px;
    }
}

@media screen and (min-width: 768px) {
    .titleWrapper {
        background-position-y: 40%;
    }

    .titleWrapper > div {
        padding: 60px 0px;
    }

    .contactWrapper {
        margin: 25px 0px;
    }

    .officeImgWrapper {
        height: 350px;
    }

    .officeImgColor {
        width: 300px;
        height: 200px;
    }
}

@media screen and (min-width: 992px) {
    .titleWrapper {
        background-position-y: 43%;
    }

    .titleWrapper > div {
        padding: 60px 0px;
    }

    .contactWrapper {
        margin: 30px 0px;
    }

    .officeImgColor {
        width: 200px;
        height: 140px;
    }

    .officeImgWrapper {
        height: 100%;
    }
}

@media screen and (min-width: 1200px) {
    .titleWrapper {
        background-position-y: 43%;
    }

    .titleWrapper > div {
        padding: 80px 0px;
    }

    .contactWrapper {
        margin: 40px 0px;
    }
} */