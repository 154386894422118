.wrapper {
    --spacing: 10px
}

.titlePriceDiv {
    margin-top: var(--spacing);
}

.title {
    font-size: var(--re-text-4);
    margin-bottom: var(--spacing);
}

.title.loading {
    height: calc(var(--re-text-4) + var(--spacing));
}

.priceLabel {
    font-size: var(--re-text-1);
}

.priceLabel.loading {
    height: var(--re-text-1);
    margin-bottom: 10px;
}

.price {
    font-size: var(--re-text-3);
}

.price.loading {
    height: var(--re-text-3);
}


.swiperWrapper {
    margin-top: var(--spacing);
    height: 250px;
}

.summaryWrapper {
    margin-top: var(--spacing);
}

.summaryWrapper.loading {
    height: 100px;
    margin-bottom: var(--spacing);
}

.descriptionWrapper {
    margin-top: var(--spacing);
}

.detailsWrapper {
    margin-top: calc(2 * var(--spacing));
}

.featureWrapper {
    margin-top: calc(2 * var(--spacing));
}

.mapWrapper {
    margin-top: var(--spacing);
    margin-bottom: calc(var(--spacing) * 2  );
}

@media screen and (min-width: 768px) {
    .wrapper {
        --spacing: 15px
    }

    .swiperWrapper {
        height: 400px;
    }
}

@media screen and (min-width: 992px) {
    .wrapper {
        --spacing: 30px
    }
    .price, .priceLabel {
        text-align: right;
    }
}

@media screen and (min-width: 1400px) {
    .swiperWrapper {
        height: 450px;
    }
}